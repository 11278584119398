import React, { useEffect } from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/Utils/seo"
import ReactRoundedImage from "react-rounded-image";
import ProfessionalPhoto from "../images/professionalPhoto.jpg";
import Engineer from "../images/courageousEngineer.jpeg";
import Markdown from 'markdown-to-jsx';

import posthog from 'posthog-js'


export default function About(props) {

	useEffect(() => {
		posthog.init('phc_YqgQteGmBLmUzof8cQHlhU0fo4kwUl33F976pS4WiP6', { api_host: 'https://app.posthog.com' })
		posthog.capture('About Page', { property: 'value' })
	}, [])
	
	return (
		<Layout>
			{/* siteTitle specifies that the title will the siteTitle alone */}
			<SEO title="siteTitle" />
			<section className="About">
				<h1>About</h1>
				<p>
					Howdy! Bosco here.
					<br />
					<br />
					A engineering leader, product manager by training/experience and a builder at heart(entrepreneur).
					<br />
					<div>
						{/* <img
							src={Engineer}
							width="50%"
						>
						</img> */}
						<img
							src={ProfessionalPhoto}
							width="50%"
							height="30%"
						>
						</img>
					</div>
				
					{/* <ReactRoundedImage
						image={Engineer}
						imageWidth="150"
						imageHeight="150"
						roundedSize="13"
						paddingLeft="30%"
					/> */}
					<br />
					<br />

					Named after <a href="https://en.wikipedia.org/wiki/John_Bosco">Saint John Bosco</a>, a 19th century Italian saint, educator and writer.
					Founder of the Salesians order, a religious congregation of priests, brothers, nuns and lay people dedicated to the service of the young, especially the disadvantaged and marginalized.
					<br />
					<br />



					I'm like a damascus sword, a product of 6 cultures and 4 nations.
					Went to University of London because I loved <a href="https://www.britannica.com/biography/Mahatma-Gandhi">Mahatma Gandhi</a> and <a href="https://www.britannica.com/biography/Nelson-Mandela/">Nelson Mandela. </a>
					<br />
					<br />
					
					If I were to describe myself, I'd say I'm a semi-transhumanist(not all problems can be solved by technology alone...for some I rely on Christ). 
					My purpose in life is to solve hard problems, and alleviate suffering the best ways that I can. 
					Having a preternatural aversion to complacency, chose technology to do this first, I saw its potential at a young age, it's what shaped my thinking..
					<br />
					<br />
				
					Learning is my superpower, I live to learn till I can learn to live. Somehow managed to hold on to my child like curiosity that schooling systems seem to desperately take away from us at a young age.
					<br />
					<br />

					I LOVE reading, here are some of my favorite <a href="https://www.boscorona.com/library">books</a>
					<br />
					<br />

					I've worked at many startups and large organizations, some of which include;
					<br />
					- Tesla
					<br />
					- Ginger.com
					<br />
					- MailChimp
					<br />
					- Finn.AI (Conversational AI for banks)
					<br />
					- Datacy.com 
					<br />
					<br />
					I'm interested in problems around, Applied AI, Healthcare, Education, Finance, SaaS, Aerospace/Space, 
					Distributed Systems, Clean Energy/Transport and Food/Distribution.
					<br />
					<br />
					What I believe, my principles, my values, what I choose to defend in life and death, so help me God:
					<br />
					<br />
					- <b>There is a God</b>, a creator, a simulator constructor..if you will. The universal constants are too well defined for reality as we know it to exist. 
					A small percent of error would deem this reality non-existent. <a href="https://www.simulation-argument.com/simulation.pdf" target="_blank">The Simulation Theory</a> argument seems to point to this too. Pascals wager and the <a href="https://plato.stanford.edu/entries/ontological-arguments/" target="_blank">modal ontological arguments</a> for the existence of God hold true for me.
					Note: I've attempted to disprove the existence of God using modal logic, I was unable to disprove the existence of the devil(Evil exists, hence the inverse follows).
					<br />
					<br />
					- <b>Play infinite sum games.</b> In life there are zero sum games and infinite sum games. I enjoy playing infinite sum games with long term people.
					<br />
					<br />
					- <b>The Golden Rule.</b> A wise man(God) once said; "In everything, then, do to others as you would have them do to you. For this is the essence of the Law and the Prophets."
					If every human followed this law, we would probably live in perfect harmony. 
					<br />
					<br />
					- <b>The fear of God is the beginning of wisdom.</b> When we loose the fear of God, we partake in un-fruitful actions, when we partake in un-fruitful actions(since there are no longer consequences) we map our behaviour to the identity of the unwise.
					<br />
					<br />
					- <b>You reap what you sow.</b> "Do not be deceived: God is not to be mocked. Whatever a man sows, he will reap in return." 
					Almost 2B people believe this(Christians) and 1.6 B people believe this(Hindus), in the form of Karma. When we do good, we get good. When we do evil we get it in return, maybe not directly, but indirectly.
					I've observed this principle first hand and second.. Divine Providence is real. The founding fathers of the US end with the line, "And for the support of this Declaration, with a firm reliance on the protection of divine Providence, we mutually pledge to each other our Lives, our Fortunes and our sacred Honor."
					<br />
					<br />
					- <b>Humans are inherently biased and flawed</b>, call it original sin if you will. We're not born perfect beings..which is why work is needed to become perfect.
					<br />
					<br />
					- <b>Flat hierarchies win</b> over bloated siloed management structures. Have less managers more engineers. Small teams, with high chemistry.
					<br />
					<br />
					- <b>The laws of physics and divine laws are the only constraints of reality</b>, every other constraint is made up by human beings. Challenge it, redefine it.
					<br />
					<br />
					- <b>Life is very short</b>, every moment is precious a gift, don't waste it on things that don't matter.
					<br />
					<br />
					- <b>I respect, value and hold high regard to engineers</b>(and other STEM field contributors). We work within the constraints of truth, are bound by truth. Compared to our counter-parts. 
					Without the truth, the systems, the bridges, the buildings would all collapse, airplanes and satellites would fall out of the sky. This is why I value engineers. We don't have room for bullshit...
					<br />
					<br />
					- Enthusiasm, attitude and perspective are at the core pillars of success.
					<br />
					<br />
					- <b>Dunning Kruger effect applies to almost everything</b>, have the self awareness to recognize on which part of the curve you're in.
					<br />
					<br />
					- <b>The essence of a company lies in its people</b>, not in its technology, products, or market. It's the people who are paramount. Prioritize them, for it is people who form the core of companies. Great people are the foundation of great companies.
					<br />
					<br />
					- You can have <b>anything</b> in life, but <b>not everything.</b> Choose wisely.
					<br />
					<br />
					- <b>Humility.</b> It's surprisingly easier to navigate life with humility than with pride. In my younger years, my ego often got the best of me. Through the gift of adversity, I've come to recognize the value of humility.
					<br />
					<br />
					- The market incentivizes <b>Truth</b>. Strive to stay as close to the truth as possible, if not entirely within its constraints. It's the only way to achieve long-term success. Do this, and the market will nurture you, like a mother cradling a newborn in her bosom.
					<br />
					<br />
					- A ship is built to sail the seas. We don't construct ships just to keep them docked, showcasing them to our friends. We build ships to venture out and fulfill their purpose. Similarly, go out into the world and do what you're meant to do. As the LORD said to Abram, 'Leave your country, your kindred, and your father's household, and go to the land I will show you.' - Genesis 12:1.
					<br />
					<br />

					- Bosco
				</p>
			</section>
		</Layout>
	)
}
